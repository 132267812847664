import { DivisionEnum, MenuItemData } from './interfaces/lib-api-interfaces';

const isDashboard = false;
export const shoppingCart = true;
export const analytics = true;
export const loginAllowed = true;
export const serverIp = "https://jacobschimes.com";
const targetDomain = "jacobschimes.com";

export const adminCustId = 1;
export const productGraphicsBaseUrl = (window.location.hostname === "localhost" ? process.env.PUBLIC_URL : serverIp) + "/graphics";

export const alignProducts = "bottom";      // or top

// themes is a standard object for passing to custom components
/* typical uses:
    backColor10 - input fields, forecolor when background is reversed
    backColor25 - buttons, logo, dropdown menus
    backColor50 - captions on grids (e.g., store near you)
*/
const sansFonts = "Arial,Helvetica,sans-serif";
export const themes = {
    defaultFonts: "Verdana,sans-serif",
    sansFonts,
    foreColor: "#4a5929", // Pantone 574,
    backColor50: "#d3d584",  //  583 at 50%
    backColor25: "#e9ecc2",  //  583 at 25%
    backColor10: "#f6f8e6", // 583 at 10%
    h1FontFamily: null,
    navbarFont: sansFonts,
    modalStyles: { fontSize: "16px", fontFamily: sansFonts, lineHeight: "24px", padding: "16px" }
}

export const siteMap = [
    { caption: "Stores near me", href: "/stores" },
    { caption: "Order online", href: "https://fernsgarden.com/jacobs-chimes/" },
    { caption: "Info for retailers", href: "/retailers" },
    { caption: "Contact us", href: "/contact" }
];

const productsNotInDB: MenuItemData[] | null = null;

export const nonProductMenu: MenuItemData[] = [
    {
        caption: "STORE LOCATIONS", userData: "rtlOnly", popupItems: [
            { caption: "Find a store", href: "/stores" },
            { caption: "Order online", href: "https://fernsgarden.com/jacobs-chimes/" }
        ]
    },
    { caption: "STORE LOCATIONS", href: "/stores", userData: "wsOnly" },
    { caption: "FOR RETAILERS", href: "/retailers" },
    { caption: "REPAIRS", href: "/repair" },
    { caption: "CONTACT", href: "/contact" }
];

// categories are fetched from master page and persisted into local storage
export const fetchProductsUrl = "/api/products/J/";
export const fetchCategoriesUrl = "/api/categories/J";
export const fetchOrderUrl = "/api/getPendingOrder";
export const facebookUrl = "https://www.facebook.com/Chimeland/";
export const banner = "PROUDLY MADE IN THE U.S.A";
export const logoUrl = "horizontal_logo.png";
export const division = DivisionEnum.jmc;
export const catalogUrl = productGraphicsBaseUrl + "/catalog/jacobs_musical_chimes_catalog_june_2024.pdf";
export const companyName = "Jacob's Musical Chimes";
export const companyPhone = "1-800-627-5840";
// following for store near you if none found
export const orderOnlineUrl = "https://fernsgarden.com/jacobs-musical-chimes/";
export const orderOnlineText = "Chime lovers: buy online";
export const twigDividerImage = "other/twig.gif";


export default {
    isDashboard,
    shoppingCart,
    alignProducts,
    analytics,
    loginAllowed,
    serverIp,
    targetDomain,
    adminCustId,
    productGraphicsBaseUrl,
    themes,
    siteMap,
    productsNotInDB,
    nonProductMenu,
    fetchProductsUrl,
    fetchCategoriesUrl,
    fetchOrderUrl,
    facebookUrl,
    banner,
    logoUrl,
    division,
    catalogUrl,
    companyName,
    companyPhone,
    orderOnlineUrl,
    orderOnlineText,
    twigDividerImage
}